@import url(https://fonts.googleapis.com/css2?family=Spartan&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Spartan', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.name { 
  margin-left: "auto";
}
.images {
  grid-column-gap: 40px;
  -webkit-column-gap: 40px;
          column-gap: 40px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 30px;
}
.painting {
  max-height: 400px;
  padding:10px;
  margin-left: 30px;
  border-style: solid;
  -webkit-animation: fadeIn 0.5s;
          animation: fadeIn 0.5s;
}
.paintingEnlarged {
  max-width: 100vw;    
  max-height: 150vh;    
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.bio {
  margin: 50px;
  display: flex;
}
.shop {
  margin: 50px;  

}

.bioImg {
  float: right;
}

.model {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  width: 80%;
}

@media(max-width:767px) {
  .bioImg {
    float: none;
  }
  .bio {
    display: block;
    margin: 0px;
  }
  .painting, .paintingEnlarged {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100vw;
    padding-left:0;
    padding-right:0;
    border-style: none;
  }
  .images {
    margin: 0px;
  }
}
.scrollTop {
  position: fixed; 
  width: 50px;
  bottom: 20px;
  right: 20px;
  align-items: center;
  height: 50px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  -webkit-animation: fadeIn 0.3s;
          animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTop:hover{
  opacity: 1;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
span {
  border: 2px solid #4F5D75;
  padding: 10px;
}


/* Layout Styling */

#container {
  width: 100%;
  margin: 0 auto;
  padding: 50px 0 150px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}


/* Button Styles */

.button {
  display: inline-flex;
  height: 40px;
  width: 100px;
  border: 2px solid #BFC0C0;
  margin: 15px;
  color: #BFC0C0;
  text-transform: uppercase;
  text-decoration: none;
  font-size: .8em;
  letter-spacing: 1.5px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.blue {
  background-color: rgba(0, 0, 255, 0.349);
}
.green {
  background-color: rgba(0, 168, 140, 0.349);
}
.orange {
  background-color: rgb(250, 114, 23);
}
.blackText {
  color: #000000;
}
a {
  color: #BFC0C0;
  text-decoration: none;
  letter-spacing: 1px;
}


/* First Button */

#arrow-hover {
  width: 15px;
  height: 10px;
  position: absolute;
  transform: translateX(60px);
  opacity: 0;
  transition: all .15s cubic-bezier(.14, .59, 1, 1.01);
  margin: 0;
  padding: 0 5px;
}

a#button-1:hover img {
  width: 15px;
  opacity: 1;
  transform: translateX(50px);
}


/* Second Button */

#regularButton {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

#regularButton a {
  position: relative;
  transition: all .35s ease-Out;
}

#slide {
  width: 100%;
  height: 100%;
  left: -200px;
  background: #BFC0C0;
  position: absolute;
  transition: all .35s ease-Out;
  bottom: 0;
}

#regularButton:hover #slide {
  left: 0;
}

#regularButton:hover a {
  color: #2D3142;
}


/* Third Button */

#button-3 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

#button-3 a {
  position: relative;
  transition: all .45s ease-Out;
}

#circle {
  width: 0%;
  height: 0%;
  opacity: 0;
  line-height: 40px;
  border-radius: 50%;
  background: #BFC0C0;
  position: absolute;
  transition: all .5s ease-Out;
  top: 20px;
  left: 70px;
}

#button-3:hover #circle {
  width: 200%;
  height: 500%;
  opacity: 1;
  top: -70px;
  left: -70px;
}

#button-3:hover a {
  color: #2D3142;
}


/* Fourth Button */

#button-4 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

#button-4 a {
  position: relative;
  transition: all .45s ease-Out;
}

#underline {
  width: 100%;
  height: 2.5px;
  margin-top: 15px;
  align-self: flex-end;
  left: -200px;
  background: #BFC0C0;
  position: absolute;
  transition: all .3s ease-Out;
  bottom: 0;
}

#button-4:hover #underline {
  left: 0;
}


/* Fifth Button */

#button-5 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

#button-5 a {
  position: relative;
  transition: all .45s ease-Out;
}

#translate {
  transform: rotate(50deg);
  width: 100%;
  height: 250%;
  left: -200px;
  top: -30px;
  background: #BFC0C0;
  position: absolute;
  transition: all .3s ease-Out;
}

#button-5:hover #translate {
  left: 0;
}

#button-5:hover a {
  color: #2D3142;
}


/* Sixth Button */

#button-6 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

#button-6 a {
  position: relative;
  transition: all .45s ease-Out;
}

#spin {
  width: 0;
  height: 0;
  opacity: 0;
  left: 70px;
  top: 20px;
  transform: rotate(0deg);
  background: none;
  position: absolute;
  transition: all .5s ease-Out;
}

#button-6:hover #spin {
  width: 200%;
  height: 500%;
  opacity: 1;
  left: -70px;
  top: -70px;
  background: #BFC0C0;
  transform: rotate(80deg);
}

#button-6:hover a {
  color: #2D3142;
}


/* Seventh Button */

#linkButton {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

#linkButton a {
  position: relative;
  left: 0;
  transition: all .35s ease-Out;
}

#dub-arrow {
  width: 100%;
  height: 100%;
  background: #BFC0C0;
  left: -200px;
  position: absolute;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .35s ease-Out;
  bottom: 0;
}

#linkButton img {
  width: 20px;
  height: auto;
}

#linkButton:hover #dub-arrow {
  left: 0;
}

#linkButton:hover a {
  left: 150px;
}

@media screen and (min-width:1000px) {
  h1 {
    font-size: 2.2em;
  }
  #container {
    width: 50%;
  }
}
.flexbox-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 600px;
}

.print-image { 
  height:300px;
  width:auto;
}

.print {
  margin: 20px;
  padding:25px;
  float:none;
  background-color: rgba(0, 0, 0, 0.3);
}


@media(max-width:767px) {
  .flexbox-container {
    display: unset;
    flex-direction: unset;
    flex-wrap: unset;
    justify-content: unset;
    max-width: unset;
    margin:0px;
    padding: 0px;
  }
  

  .print {
    margin: 0px;
    padding: 0px;
    float:none;
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .print-image {  
    width:100%;
    height: auto;
  }
  .print {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100vw;
    padding-left:0;
    padding-right:0;
    border-style: none;
  }
}




