.flexbox-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 600px;
}

.print-image { 
  height:300px;
  width:auto;
}

.print {
  margin: 20px;
  padding:25px;
  float:none;
  background-color: rgba(0, 0, 0, 0.3);
}


@media(max-width:767px) {
  .flexbox-container {
    display: unset;
    flex-direction: unset;
    flex-wrap: unset;
    justify-content: unset;
    max-width: unset;
    margin:0px;
    padding: 0px;
  }
  

  .print {
    margin: 0px;
    padding: 0px;
    float:none;
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .print-image {  
    width:100%;
    height: auto;
  }
  .print {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100vw;
    padding-left:0;
    padding-right:0;
    border-style: none;
  }
}



