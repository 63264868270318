@import url('https://fonts.googleapis.com/css2?family=Spartan&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Spartan', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.name { 
  margin-left: "auto";
}
.images {
  column-gap: 40px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 30px;
}
.painting {
  max-height: 400px;
  padding:10px;
  margin-left: 30px;
  border-style: solid;
  animation: fadeIn 0.5s;
}
.paintingEnlarged {
  max-width: 100vw;    
  max-height: 150vh;    
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.bio {
  margin: 50px;
  display: flex;
}
.shop {
  margin: 50px;  

}

.bioImg {
  float: right;
}

.model {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  width: 80%;
}

@media(max-width:767px) {
  .bioImg {
    float: none;
  }
  .bio {
    display: block;
    margin: 0px;
  }
  .painting, .paintingEnlarged {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100vw;
    padding-left:0;
    padding-right:0;
    border-style: none;
  }
  .images {
    margin: 0px;
  }
}
.scrollTop {
  position: fixed; 
  width: 50px;
  bottom: 20px;
  right: 20px;
  align-items: center;
  height: 50px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTop:hover{
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}