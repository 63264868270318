@import url('https://fonts.googleapis.com/css?family=Raleway:300,400');


span {
  border: 2px solid #4F5D75;
  padding: 10px;
}


/* Layout Styling */

#container {
  width: 100%;
  margin: 0 auto;
  padding: 50px 0 150px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}


/* Button Styles */

.button {
  display: inline-flex;
  height: 40px;
  width: 100px;
  border: 2px solid #BFC0C0;
  margin: 15px;
  color: #BFC0C0;
  text-transform: uppercase;
  text-decoration: none;
  font-size: .8em;
  letter-spacing: 1.5px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.blue {
  background-color: rgba(0, 0, 255, 0.349);
}
.green {
  background-color: rgba(0, 168, 140, 0.349);
}
.orange {
  background-color: rgb(250, 114, 23);
}
.blackText {
  color: #000000;
}
a {
  color: #BFC0C0;
  text-decoration: none;
  letter-spacing: 1px;
}


/* First Button */

#arrow-hover {
  width: 15px;
  height: 10px;
  position: absolute;
  transform: translateX(60px);
  opacity: 0;
  -webkit-transition: all .25s cubic-bezier(.14, .59, 1, 1.01);
  transition: all .15s cubic-bezier(.14, .59, 1, 1.01);
  margin: 0;
  padding: 0 5px;
}

a#button-1:hover img {
  width: 15px;
  opacity: 1;
  transform: translateX(50px);
}


/* Second Button */

#regularButton {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

#regularButton a {
  position: relative;
  transition: all .35s ease-Out;
}

#slide {
  width: 100%;
  height: 100%;
  left: -200px;
  background: #BFC0C0;
  position: absolute;
  transition: all .35s ease-Out;
  bottom: 0;
}

#regularButton:hover #slide {
  left: 0;
}

#regularButton:hover a {
  color: #2D3142;
}


/* Third Button */

#button-3 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

#button-3 a {
  position: relative;
  transition: all .45s ease-Out;
}

#circle {
  width: 0%;
  height: 0%;
  opacity: 0;
  line-height: 40px;
  border-radius: 50%;
  background: #BFC0C0;
  position: absolute;
  transition: all .5s ease-Out;
  top: 20px;
  left: 70px;
}

#button-3:hover #circle {
  width: 200%;
  height: 500%;
  opacity: 1;
  top: -70px;
  left: -70px;
}

#button-3:hover a {
  color: #2D3142;
}


/* Fourth Button */

#button-4 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

#button-4 a {
  position: relative;
  transition: all .45s ease-Out;
}

#underline {
  width: 100%;
  height: 2.5px;
  margin-top: 15px;
  align-self: flex-end;
  left: -200px;
  background: #BFC0C0;
  position: absolute;
  transition: all .3s ease-Out;
  bottom: 0;
}

#button-4:hover #underline {
  left: 0;
}


/* Fifth Button */

#button-5 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

#button-5 a {
  position: relative;
  transition: all .45s ease-Out;
}

#translate {
  transform: rotate(50deg);
  width: 100%;
  height: 250%;
  left: -200px;
  top: -30px;
  background: #BFC0C0;
  position: absolute;
  transition: all .3s ease-Out;
}

#button-5:hover #translate {
  left: 0;
}

#button-5:hover a {
  color: #2D3142;
}


/* Sixth Button */

#button-6 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

#button-6 a {
  position: relative;
  transition: all .45s ease-Out;
}

#spin {
  width: 0;
  height: 0;
  opacity: 0;
  left: 70px;
  top: 20px;
  transform: rotate(0deg);
  background: none;
  position: absolute;
  transition: all .5s ease-Out;
}

#button-6:hover #spin {
  width: 200%;
  height: 500%;
  opacity: 1;
  left: -70px;
  top: -70px;
  background: #BFC0C0;
  transform: rotate(80deg);
}

#button-6:hover a {
  color: #2D3142;
}


/* Seventh Button */

#linkButton {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

#linkButton a {
  position: relative;
  left: 0;
  transition: all .35s ease-Out;
}

#dub-arrow {
  width: 100%;
  height: 100%;
  background: #BFC0C0;
  left: -200px;
  position: absolute;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .35s ease-Out;
  bottom: 0;
}

#linkButton img {
  width: 20px;
  height: auto;
}

#linkButton:hover #dub-arrow {
  left: 0;
}

#linkButton:hover a {
  left: 150px;
}

@media screen and (min-width:1000px) {
  h1 {
    font-size: 2.2em;
  }
  #container {
    width: 50%;
  }
}